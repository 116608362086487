import loadable from '@loadable/component';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Routes
const Home = loadable(() => import('./views/Home'));

const Link = loadable(() => import('./views/Link'));

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <div style={{ height: '100%' }}>
          <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/:code/:pass" component={Link} />
            <Route path="/:code" component={Link} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;
