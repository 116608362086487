import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import * as ReactDOM from 'react-dom';
import Routes from './routes';
import { WebSocketLink } from 'apollo-link-ws';

// Apollo Client Setup
const cache = new InMemoryCache();

const API_URL = 'https://link-testing.herokuapp.com/v1/graphql';

const WS_URL = 'wss://link-testing.herokuapp.com/v1/graphql';

const httpLink = new HttpLink({
  credentials: 'include',
  uri: API_URL,
});

const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
  },
});

const terminatingLink = split(
  ({ query }) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([terminatingLink]);

const client = new ApolloClient({
  link,
  cache,
});

// Render
const App = () => <Routes />;

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);
